<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.show_conclusions") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- vaqtincha to'xtatildi -->
              <el-button
                v-if="
                  tableOrderData.length &&
                  tableOrderData[0].laboratory_conculations
                "
                @click="back()"
                >{{ $t("message.back") }}</el-button
              >

              <!-- {{ model }}
              <el-button
                v-can="'printCenter.print'"
                :loading="waitingPrint"
                @click="print('conclusion/print', model.id)"
                >{{ $t("message.print") }}</el-button
              > -->
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-tabs v-model="activeName">
            <!-- show -->

            <!-- <el-tab-pane :label="$t('message.order')" v-loading="waiting" name="first"> -->
            <el-col :span="24">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span> {{ $t("message.order") }}</span>
                </div>
                <div>
                  <el-table :data="tableOrderData" style="width: 100%">
                    <el-table-column label="№" width="110">
                      <template slot-scope="scope">
                        # {{ scope.row.id }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="diagnos"
                      :label="$t('message.clinic_diagnos')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="partner_clinic.name"
                      :label="$t('message.partner_clinic')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="department.name"
                      :label="$t('message.department')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="partner_doctor.name"
                      :label="$t('message.clinic_diagnos')"
                    >
                    </el-table-column>
                    <el-table-column prop="blok" :label="$t('message.blok')">
                    </el-table-column>
                    <el-table-column
                      prop="vial_quantity"
                      :label="$t('message.vial_quantity')"
                    >
                    </el-table-column>
                    <el-table-column :label="$t('message.material')">
                      <template slot-scope="scope">
                        {{ scope.row.material ? scope.row.material.name : "" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="created_at"
                      :label="$t('message.order_created_at')"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </el-card>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span> {{ $t("message.patient") }}</span>
                </div>
                <div>
                  <el-table :data="tablePatientData" style="width: 100%">
                    <el-table-column label="№" width="110">
                      <template slot-scope="scope">
                        # {{ scope.row.id }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="first_name"
                      :label="$t('message.first_name')"
                      width="150"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="surname"
                      :label="$t('message.surname')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="patronymic"
                      :label="$t('message.patronymic')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="born_date"
                      :label="$t('message.dob')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="gender"
                      :label="$t('message.gender')"
                    >
                    </el-table-column>
                    <el-table-column prop="code" :label="$t('message.code')">
                    </el-table-column>
                    <el-table-column prop="phone" :label="$t('message.phone')">
                    </el-table-column>
                    <el-table-column :label="$t('message.social')">
                      <template slot-scope="scope">
                        {{ scope.row.social ? scope.row.social.name : "" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="created_at"
                      :label="$t('message.date')"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </el-card>
            </el-col>
            <el-col :span="24" class="mt-2">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span> {{ $t("message.order_service") }}</span>
                </div>
                <div>
                  <el-table :data="tableServicesData" style="width: 100%">
                    <el-table-column type="index" label="#" width="80">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      :label="$t('message.service_name')"
                      width="280"
                    >
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('message.price2')">
                      <template slot-scope="scope">
                        {{ scope.row.price | formatMoney }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="discount_price"
                      :label="$t('message.discount')"
                    >
                    </el-table-column>
                    <el-table-column prop="code" :label="$t('message.code')">
                    </el-table-column>
                    <el-table-column
                      prop="lifetime"
                      :label="$t('message.lifetime_services')"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </el-card>
            </el-col>
            <!-- </el-tab-pane> -->
            <!-- <el-tab-pane :label="$t('message.all_laboratory_conclusions')" name="second">
              <el-row v-if="data == {}">
                <el-col class="ml-5" :span="8">
                  {{ $t("message.doctor_name") }} :
                  <b>
                    {{
                      data.doctor
                        ? data.doctor.name +
                          " " +
                          (data.doctor.surname ? data.doctor.surname : "") +
                          " " +
                          (data.doctor.patronymic ? data.doctor.patronymic : "")
                        : ""
                    }}
                  </b>
                </el-col>
                <el-col :span="8">
                  {{ $t("message.date") }} :
                  <b>
                    {{ data.date_time ? data.date_time : "-" }}
                  </b>
                </el-col>
                <el-divider></el-divider>
                <el-col :span="24">
                  <el-card class="ml-5" style="width: 826px" shadow="always">
                    <div class="content-table" v-html="data.content"></div>
                  </el-card>
                </el-col>
              </el-row>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import print from "@/utils/mixins/print";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [form, print, drawerChild],
  props: {
    reloadModel: { type: Boolean, default: true },
    selected_id: { default: null },
  },
  data() {
    return {
      activeName: "first",
      waitingPrint: false,
      waiting: false,
      tablePatientData: [],
      tableOrderData: [],
      tableServicesData: [],
      data: {},
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      rules: "laboratoryConclusions/rules",
      model: "laboratoryConclusions/model",
      columns: "laboratoryConclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "laboratoryConclusions/update",
      getOrder: "orders/relationShow",
      backTo: "orders/back",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    async afterOpened() {
      this.waiting = true;
      const query = { id: this.selected_id, relation: true };
      await this.getOrder(query).then((res) => {
        this.tablePatientData.push(res.data.order.patient);
        this.tableOrderData.push(res.data.order);
        this.tableServicesData = res.data.order.order_services;
        this.waiting = false;
      });
    },
    afterClosed() {
      this.tablePatientData = [];
      this.tableOrderData = [];
      this.tableServicesData = [];
      this.data = {};
    },
    back(close = true) {
      if (this.tableOrderData.length) {
        const query = {
          id: this.tableOrderData[0].id,
        };
        this.backTo(query).then((res) => {
          this.$emit("c-close", { drawer: "drawerUpdate" });
          this.parent().listChanged();
          // this.$refs["form"].resetFields();
          this.close();
        });
      }
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
